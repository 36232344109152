import React from 'react';
import ResearchContentHeader from './ResearchContentHeader';
import CategorySocialMedia from '../CategorySocialMedia';
import { useStyles } from './styles';
import './researchcontent.scss';
import clsx from 'clsx';
import {ResearchWordCount} from './ResearchWordCount';
import wordCountProvider from '../word-count/word-count';
import Container from '@material-ui/core/Container';

export default function ResearchContent({ researchData, bucketname,researchslug,basePath,images,researchLandingUrl,location }) {
  const path = researchslug;
  const classes = useStyles(images);
  const TotalContent=ResearchWordCount.WordCount(researchData);
  const wordCount = wordCountProvider(TotalContent);
  
  return (
    <>
      <div className="ResearchContent-main" id={researchData?.entryTitle}>
        <div className={clsx('bg-donuts-research-content', classes['bg-donuts-right-img'])}></div>
        <Container maxWidth="xl" className='podcastcontent-container-div'>
          <ResearchContentHeader data={researchData} images={images}/>
          <CategorySocialMedia
            data={researchData}
            bucketname={bucketname}
            type={process.env.GATSBY_CARTUS_RESEARCH}
            path={path}
            wordCount={wordCount}
            title={researchData?.researchTitle}
            landingUrl={researchLandingUrl}
            basePath={basePath}
            images={images}
            location={location}
          />
          </Container>
        
      </div>
    </>
  );
}
